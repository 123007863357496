<template>
  <Modal ref="modal">
    <template #header>
      <Slider v-if="slider.length" :settings="{ loop: true }" class="aspect-[2/1]">
        <div v-for="(media, index) in slider" class="aspect-[2/1]">
          <Image v-if="media.type === 'image'" :src="media.data.url" class="object-cover object-center h-full w-full" />
          <iframe v-if="media.type === 'youtube_embed'" :src="`${media.data.url}${index === 0 ? '&autoplay=1': ''}`" class="object-cover object-center h-full w-full" width="560" height="315" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </Slider>
      <div v-else class="aspect-[2/1]">
        <Image :src="null" class="object-cover object-center h-full w-full" />
      </div>
    </template>

    <h1 class="text-2xl font-serif">{{ product.name }}</h1>
    <HtmlContent :html="product.long_description || product.description" class="text-sm my-6" />

    <UspList v-if="usps" :usps="usps" autoflow />

    <template #footer>
      <ProductPersonSelection v-if="'persons' in product && !hidePersonSelection" group="group" :product="product" class="mb-6 pb-6 border-b border-qwr-haze-100" />
      <div class="flex flex-col lg:flex-row gap-4 justify-between items-center">
        <Price v-if="!checkout.meta.show_hotel_room_categories || (!('raw_price' in product) || product.raw_price)" :value="product.price" :affix="'available_rooms' in product ? $t('per_person_per_night') : $t('per_person_short')" class="text-2xl font-semibold" />
        <span v-if="checkout.meta.show_hotel_room_categories && 'raw_price' in product && !product.raw_price" class="text-2xl font-semibold block">{{ $t('no_extra_charge') }}</span>

        <Button v-if="is_available" variant="light" @click="$emit('select')">

          <div class="flex gap-2" v-if="hidePersonSelection">
            <LoadingIndicator :size="20" v-if="isLoading" />

            <template v-if="isSelected">
              <i class="icon-ok" />
            </template>

            <template v-else>
              <template v-if="isLoading">
                <template class="hidden lg:block">
                  {{ $t('select') }}
                </template>
              </template>

              <template v-else>
                {{ $t('select') }}
              </template>
            </template>
          </div>

          <span v-else>
            {{$t('select_for_all') }}
          </span>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import Button from '@/components/Button.vue';
  import HtmlContent from '@/components/HtmlContent.vue';
  import Image from '@/components/Image.vue';
  import Modal from '@/components/Modal.vue';
  import Price from '@/components/Price.vue';
  import Slider from '@/components/Slider.vue';
  import UspList from '@/components/UspList.vue';
  import type { ProductChoice, ProductResource, UpsellProductResource } from '@/api/resources';
  import ProductPersonSelection from '@/components/ProductPersonSelection.vue';
  import LoadingIndicator from "@/components/LoadingIndicator.vue";
  import {useFlowStore} from "@/stores/useFlowStore";

  export interface Props {
    group?: string,
    product: ProductResource | UpsellProductResource | ProductChoice,
    hidePersonSelection?: boolean,
    isSelected?: boolean,
    isLoading?: boolean
  }

  const { checkout } = useFlowStore();

  const props = defineProps<Props>();

  defineEmits<{
    (event: 'select'): void
  }>();

  const modal = ref<InstanceType<typeof Modal>>();

  const openModal = () => {
    modal.value?.openModal();
  };

  const closeModal = () => {
    modal.value?.closeModal();
  };

  const slider = computed(() => ('slider' in props.product) ? props.product.slider : []);
  const usps = computed(() => ('usps' in props.product) ? props.product.usps : []);
  const is_available = computed(() => ('is_available' in props.product) ? props.product.is_available : false);

  defineExpose({
    openModal,
    closeModal,
  });
</script>
