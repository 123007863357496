import type {MessageSchema} from '@/translations';

const messages: MessageSchema = {
  a_few_more_details: 'A few more details',
  accept_mailinglist_sentence: 'I sign up for the e-mailings of Thermen {resort} with news and personal offers',
  accept_retreats_mailinglist_sentence: 'I sign up for the e-mailings of Retreats with news and personal offers',
  accept_terms_sentence: 'I hereby confirm my agreement to the terms and conditions below; {terms_list}',
  accept_thermenfans_sentence: 'I sign up for the {link} and therefore benefit from exclusive benefits such as: offers, personalized discount vouchers, nice gifts and exclusive access to events',
  add_one: 'Add',
  all_choices: 'All choices',
  amount_of_rooms: "{n} room | {n} rooms",
  an_email_will_be_sent_to_your_email_if_you_press_reset_password_this_email_will_contain_a_link_to_request_a_new_password: 'An email will be sent to your email if you press reset password. This email will contain a link to request a new password.',
  are_you_coming_together_but_still_want_to_enjoy_your_own_room: 'Are you coming together but still want to enjoy your own room? No problem. Simply indicate your preference during booking, and we’ll make sure everything is arranged to your liking. That way, everyone can enjoy their ultimate moment of relaxation.',
  are_you_sure_you_want_to_reset_your_password: 'Are you sure you want to reset your password?',
  arrangement_choice_nr: 'Arrangement choice {n}',
  arrangement_choices: 'Arrangement @:choices',
  arrival: 'Arrival',
  arrival_and_departure_time_explanation: 'For a relaxed visit, we kindly ask you to arrive and depart at the times of your reservation. This way, together we maintain a pleasant balance in the number of guests checking in and out.',
  belgium: 'Belgium',
  business_name: 'Company name',
  cancellation_policy: 'Cancellation Policy',
  choices: 'choice | choices',
  choose_arrangement: 'Make it really your arrangement. Choose your favorite for your wellness day from the options below.',
  choose_your_arrival_and_departure_day_in_the_calendar: 'Choose your arrival and departure day in the calendar',
  choose_your_type_of_entrance: 'Choose your type of entrance',
  city: 'City',
  close: 'Close',
  comments_or_extra_information: 'Comments / extra information',
  compare_etb_comment: 'In this part of the resort, wearing swimwear is mandatory, so don\'t forget your swimwear',
  compare_etb_title: 'Thermal bath entrance',
  compare_etb_usp_1: 'Access to two of our Thermal Baths',
  compare_etb_usp_2: 'Steam bathhouse, Park conservatory, and relaxation areas with a lavender room',
  compare_etb_usp_3: 'Sunbathing areas and the bathrobe park',
  compare_etb_usp_4: 'Brasserie Bubbels',
  compare_etbs_comment: 'Our sauna area is only accessible without swimwear, except on our swimwear days (every Wednesday).',
  compare_etbs_title: 'Thermal bath + sauna entrance',
  compare_etbs_usp_1: 'Access to our Thermal Baths',
  compare_etbs_usp_2: 'Steam bathhouse, Park conservatory, and relaxation areas with a lavender room',
  compare_etbs_usp_3: 'Sunbathing areas and the bathrobe park',
  compare_etbs_usp_4: 'Brasserie Bubbels',
  compare_etbs_usp_5: 'Access to all our beneficial Sauna Facilities (indoor & outdoor)',
  compare_termal_comment: 'Because we know that everyone experiences relaxation differently, we have created two different zones in our resort. Will you book a thermal bath entrance or a thermal bath entrance incl. sauna area? See what you will enjoy:',
  compare_termal_title: 'Entrance thermal bath and entrance thermal bath + sauna',
  country: 'Country',
  customer_page_into_sentence: 'We need a little more information from you. Really a little. And of course we keep your data to ourselves. Promised.',
  date_of_birth: 'Date of birth',
  day: 'Day',
  departure: 'Departure',
  edit: 'Edit',
  email_address: 'Emailaddress',
  estimated_time_of_arrival: 'Estimated time of arrival',
  estimated_time_of_departure: 'Estimated time of departure',
  evening: 'Evening',
  extra_charge: 'Surcharge',
  filter: 'filter',
  first_name: 'First Name',
  fold: "Fold",
  for_who: 'For who?',
  forgot_your_password_no_problem: 'Forgot your password? No problem.',
  from_price: "fr.",
  germany: 'Germany',
  go_to_the_website: 'Go to the website',
  hide_all_single_rooms: 'Hide all single rooms',
  hotel_room_availability: 'Still {number} available',
  hotel_room_choice: 'Hotel room choice',
  hotel_room_type_1: 'one person room',
  hotel_room_type_2: 'two person room',
  hotel_room_type_3: 'three person room',
  hotel_room_type_4: 'four person room',
  hotel_rooms: 'Hotel rooms',
  hotel_rooms_page_into_sentence: 'Are you coming to stay with us?',
  house_number: 'House number',
  house_number_addition: 'Infix',
  house_rules: 'house rules',
  how_long: 'How long?',
  how_would_you_like_to_book_your_relaxing_moment: 'How would you like to book your relaxing moment?',
  i_have_a_my_thermen_account: 'I have a Thermen Resorts account',
  i_want_to_create_a_my_thermen_account: 'I want to create a Thermen Resorts account',
  i_want_to_create_a_my_thermen_account_tooltip: 'Faster reservations and never having to fill in your details again. A Thermen Resorts account offers you more. More convenience and more benefits. Note! Your account is activated by clicking on the link in the verification email.',
  i_want_to_make_a_reservation_without_an_account: 'I want to make a reservation without an account',
  i_would_like_to_create_an_account: 'I would like to create an account',
  invoice_comment_placeholder: 'Comments/special details (e.g. purchase order number',
  invoice_information: 'Employers invoice information',
  last_name: 'Last Name',
  login: 'Log in',
  logout: 'Log out',
  lunch_and_diner: 'Lunch and diner',
  lunch_and_diner_page_into_sentence: 'Your wellness day even more complete and even nicer? Discover our infusions, rituals, meditations and other pleasant moments of relaxation... Our tip? Choose what makes you happy!',
  make_it_your_day: 'Make it your day!',
  mobile_phone_number: 'Mobile phone number',
  month: 'Month',
  more_info: 'More info',
  more_information: 'More information',
  morning: 'Morning',
  mr: 'Mr.',
  ms: 'Ms.',
  my_preferences_for_a_room_are: 'My preferences for a room are',
  name_infix: 'Infix.',
  need_help_with_your_room_choices: 'Need help with your room choices?',
  next: 'Next',
  no_extra_charge: 'No extra charge',
  notice_chosen_date_or_time_is_unavailable: 'Chosen date and/or time is unavailable!',
  notice_there_are_no_hotel_rooms_unavailable: 'There are no rooms available on the selected date.',
  notice_this_hotel_room_is_unavailable: 'This hotel room is unavailable for this day!',
  number_of_persons: '{n} person | {n} persons',
  or_stay_with_us_for_a_good_night_sleep: 'Or stay with us for a good night sleep?',
  other: 'Other',
  password: 'Password',
  password_confirmation: 'Repeat password',
  pay: 'Pay',
  per_night: 'per night',
  per_person_including_breakfast_starting_from: 'p.p. including breakfast starting from',
  per_person_per_night: 'p.p.p.n.',
  per_person_short: 'p.p.',
  persons: 'person | persons',
  persons_count: 'Numfer of persons',
  previous: 'Previous',
  prices_are_in_euro: 'Prices in € @:per_person_short',
  privacy_policy: 'Privacy Policy',
  receive_a_present_on_your_birthday: 'Receive a present on your birthday?',
  reload: 'Reload',
  request_your_new_password_here: 'Request your new password here.',
  reset: 'Reset',
  reset_filters: 'Reset filters',
  reset_password: 'Reset password',
  rituals_extras: 'Extras on your sauna day',
  rituals_extras_page_into_sentence: 'Your wellness day even more complete and even nicer? Discover our infusions, rituals, meditations and other pleasant moments of relaxation... Our tip? Choose what makes you happy!',
  rooms_count: "Number of rooms",
  select: 'Select',
  select_all: 'Select all',
  select_for_all: 'Select for all',
  selected: 'Selected',
  send_invoice: 'My employer pays the invoice.',
  share_a_room_together_select_one_of_the_available_room_options_below: 'Share a room together, select one of the available room options below.',
  show_all_single_rooms: 'Show all single rooms',
  something_went_wrong_while_retrieving_your_data: 'Something went wrong while retrieving your data.',
  steps: {
    choices: 'Your choices',
    complete: 'Complete',
    customer_details: 'Customer details',
    reservation: 'Reservation',
  },
  street: 'Street',
  swimwear_day: 'Swimwear day',
  swimwear_day_tooltip_sentence: 'The days marked in blue in the calendar are swimwear days. Swimwear is mandatory on these days.',
  terms_and_conditions: 'Terms and conditions',
  terms_item_1: 'My arrival and departure time (so that any treatments can take place within half an hour after the specified arrival time and before the specified departure time)',
  terms_item_2: 'I have read the {house_rules}',
  terms_item_3: 'The {terms_and_conditions}, {cancellation_policy} and {privacy_policy}',
  the_difference: 'The difference',
  the_netherlands: 'Netherlands',
  there_are_no_selectable_products_available_at_the_selected_time: 'There are no selectable products available at the selected time.',
  thermenfans_program: 'Thermenfans-program',
  total: 'Total',
  treatment_page_into_sentence: 'Your day with us can be even more complete. A massage? A treatment..? Our tip: choose what makes you happy, that is always the best choice.',
  unavailable: 'Unavailable',
  view_reservation: 'View reservation',
  we_put_together_the_available_options_for_you: 'We put together the available options for you...',
  what_is_the_difference: 'What is the difference?',
  what_would_you_like_to_book: 'What would you like to book',
  when_are_you_coming_to_relax: 'When are you coming to relax?',
  when_resetting_previously_entered_data_will_be_lost: 'When resetting, previously entered data will be lost.',
  which_type_of_treatment_suits_you: 'Which type of treatment suits you:',
  year: 'Year',
  you_have: 'You have',
  you_have_number_of_choices: 'You have <strong>{n}</strong> @:choices',
  you_want_to_book_with: 'You want to book with <strong>{persons}</strong> and want <strong>{rooms}</strong> with an arrival date of {arrival_date} and a departure date of {departure_date}.',
  your_experience: 'Your experience:',
  your_first_step_to_great_enjoyment: 'Your first step to great enjoyment!',
  zip_code: 'Zip code',
  view: 'View',
  hide: 'Hide',
};

export default messages;
