<template>
  <div v-if="products.length > 0" class="relative">
    <LoadingOverlay v-if="$loadingstore.changing_product_filters" />

    <div class="flex flex-col gap-6">
      <HotelRoomChoiceCard v-for="product in visibleRooms" :key="product.id" group="hotelroom" :product="product" />
      <HotelRoomChoiceCard v-if="showHiddenRooms && hiddenRooms.length > 0" v-for="product in hiddenRooms" :key="product.id" group="hotelroom" :product="product" />
    </div>
    <button v-if="hiddenRooms.length > 0" type="button" @click="showHiddenRooms = !showHiddenRooms" class="flex justify-start items-center gap-3 py-4 my-4 w-full font-semibold uppercase tracking-wide text-sm">
      {{ showHiddenRooms ? $t('hide_all_single_rooms') : $t('show_all_single_rooms') }}
      <ChevronIcon class="h-3 flex-none transition-transform" :class="{'rotate-90': !showHiddenRooms, '-rotate-90': showHiddenRooms}" />
    </button>
  </div>
</template>

<script lang="ts" setup>
  import type { UpsellProductResource } from '@/api/resources';
  import HotelRoomChoiceCard from '@/components/HotelRoomChoiceCard.vue';
  import ChevronIcon from 'thermen-theme/icons/chevron.svg?component';
  import { computed, ref } from 'vue';
  import LoadingOverlay from "@/components/LoadingOverlay.vue";

  export interface Props {
    personsCount: number,
    products: UpsellProductResource[],
  }

  const props = defineProps<Props>();

  const showHiddenRooms = ref(false);

  const visibleRooms = computed(() => {
    const rooms = props.products.filter(room => props.personsCount >= 3 || props.personsCount < 3 && room.required_number_of_persons === props.personsCount);

    // Fallback.
    // When there are no rooms available for the number of persons,
    // then we give back at lease the first room regardless of required number of persons.
    if (rooms.length === 0 && props.products.length > 0) {
      return [props.products[0]];
    }

    return rooms;
  });

  const hiddenRooms = computed(() => {
    const rooms = props.products.filter(room => props.personsCount < 3 && room.required_number_of_persons !== props.personsCount);

    // Filter out the fallback product.
    if (visibleRooms.value.length === 1) {
      return rooms.filter(room => room.id !== visibleRooms.value[0].id);
    }

    return rooms;
  });
</script>
